import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatIconModule} from '@angular/material/icon';
import {FlexLayoutModule} from "@ngbracket/ngx-layout";
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDividerModule} from '@angular/material/divider';
import {MatSelectModule} from '@angular/material/select';


@NgModule({
  imports: [MatDividerModule, MatButtonModule, MatCardModule, MatInputModule, MatProgressBarModule, MatProgressSpinnerModule, MatIconModule, FlexLayoutModule, MatTooltipModule, MatCheckboxModule, MatSelectModule],
  exports: [MatDividerModule, MatButtonModule, MatCardModule, MatInputModule, MatProgressBarModule, MatProgressSpinnerModule, MatIconModule, FlexLayoutModule, MatTooltipModule, MatCheckboxModule, MatSelectModule],
})
export class MaterialModule { }

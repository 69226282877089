import { Component, OnInit } from '@angular/core';
import { ClientApplicationService } from '../services/client-application.service';
import { LogInService } from '../services/log-in.service';
import { AppComponent } from '../app.component';
import { catchError, map, tap } from 'rxjs/operators';
import { UrlService } from '../services/url.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.css']
})
export class PasswordComponent implements OnInit {

	isBusy = false;
	lastError = null;
	emailSent = false;
	email = null;
  applicationId = null;

  constructor(private logInService: LogInService, private urlService: UrlService, private caService: ClientApplicationService, private route: ActivatedRoute) { }

  ngOnInit() {

		this.urlService.storeUrlState();
		this.email = this.route.snapshot.paramMap.get('username');
		this.applicationId = this.route.snapshot.queryParams.applicationId;

    if (this.applicationId) {
      this.caService.getData().subscribe(ca => {
        //this.updateUI(ca)
      });
    }

  }

	requestPassword(email: String): void {

		this.isBusy = true;
		this.emailSent = false;

		this.logInService.requestPassword(email, this.applicationId).subscribe(x => {
			this.lastError = null;
			if (x.result === "success") {
				this.emailSent = true;
			}
			this.isBusy = false;

		}, error => {
			console.log(error.error);
			this.lastError = error.error;
			this.isBusy = false;
		});

	}

}

<mat-progress-bar mode="indeterminate" *ngIf="!appLoaded"></mat-progress-bar>
<div fxLayout="row" fxLayoutAlign="center center" style="min-height: 99dvh">

	<div fxFlex="500px" fxFlex.xs="100%" *ngIf="appLoaded" class="cardContainer">
		<mat-card appearance="outlined" fxFlex.xs="100%" style="padding:0; width: 100%;" ngStyle.xs="mat-elevation-z0" ngStyle.gt-xs="mat-elevation-z20">
			<div style="padding: 30px" class="cardContainer" fxLayout="column" fxLayoutAlign="space-evenly">
				<div style="text-align: center">
          <img style="width: 100%; max-width: 300px; margin-bottom: 20px; margin-top: 20px;" src="{{logoUrl}}" />
        </div>
        <div class="router-outlet-container" fxFlex.xs="75%">
          <router-outlet></router-outlet>
        </div>
        <div *ngIf="clientApplication?.settings?.account?.disableCreate !== true && showSignUpLink" fxHide fxShow.xs>
          <div style="text-align: center">{{ 'DONT_HAVE_ACCOUNT' | translate }}</div>
          <button id="createAccountButton" type="button" mat-button routerLink="/create-account" [disabled]="isBusy" *ngIf="clientApplication && clientApplication.settings.account.disableCreate !== true">{{ 'SIGN_UP' | translate }}</button>
        </div>
			</div>
		</mat-card>
	</div>

</div>

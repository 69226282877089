export const LANG_MAPPINGS= [
  { shortCode: 'bs', targetCode: 'bs_BA' },
  { shortCode: 'da', targetCode: 'da_DK' },
  { shortCode: 'de', targetCode: 'de_DE' },
  { shortCode: 'en', targetCode: 'en_GB' },
  { shortCode: 'es', targetCode: 'es_ES' },
  { shortCode: 'fi', targetCode: 'fi_FI' },
  { shortCode: 'fr', targetCode: 'fr_FR' },
  { shortCode: 'ga', targetCode: 'ga_IE' },
  { shortCode: 'hr', targetCode: 'hr_HR' },
  { shortCode: 'is', targetCode: 'is_IS' },
  { shortCode: 'it', targetCode: 'it_IT' },
  { shortCode: 'nl', targetCode: 'nl_NL' },
  { shortCode: 'nb', targetCode: 'nb_NO' },
  { shortCode: 'no', targetCode: 'nb_NO' },
  { shortCode: 'pl', targetCode: 'pl_PL' },
  { shortCode: 'ru', targetCode: 'ru_RU' },
  { shortCode: 'sr', targetCode: 'sr_SR' },
  { shortCode: 'sv', targetCode: 'sv_SE' },
  { shortCode: 'en-gb', targetCode: 'en_GB' },
  { shortCode: 'en-uk', targetCode: 'en_GB' }
];

import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Pipe({name: 'translateWithSuffix'})
export class TranslateWithSuffix implements PipeTransform {

    constructor(private translate: TranslateService) {
    }

    transform(key: string, suffix: string, args?: any): Observable<any> {
        return this.translate.get(key + '_' + suffix, args).pipe(
            map((translation) => {
                if (translation != key + '_' + suffix) {
                    return translation;
                } else {
                    // Since we have received one translation for the current language we know that everything is loaded, it should be safe to use instant
                    return this.translate.instant(key, args);
                }
            })
        );
    }
}

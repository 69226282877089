<mat-progress-bar mode="indeterminate" *ngIf="isBusy"></mat-progress-bar>
<div class="delegate-form">

  <div *ngIf="delegatedApplication" style="text-align: center; padding: 20px">

    <div>
      <img src="{{ user ? user.iconUrl : ''}}" style="min-height: 70px; max-height: 70px;" height="70px">
      <div style="font-weight: bold; padding: 10px"><span *ngIf="user">{{ user.firstName }} {{ user.lastName }}</span></div>
    </div>
    <br>
    <div [innerHTML]="'DELEGATE_ACCESS_INFO' | translate:{delegatedApplicationName: delegatedApplication.name, clientApplicationName: this.clientApplication.name } "></div>
    <br><br>
    <div *ngFor="let p of privileges" style="font-weight: bold">{{ 'DELEGATE_PRIVILEGE_' + p | translate }}</div>


    <div class="form-buttons" style="text-align:right; margin-top: 50px">
      <button mat-button [disabled]="isBusy" (click)="cancel()">{{ 'DO_NOT_ALLOW' | translate }}</button>
      <button mat-raised-button color="primary" [disabled]="isBusy" (click)="grantAccess()">{{ 'ALLOW' | translate }}</button>
    </div>


  </div>


</div>

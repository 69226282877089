import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { LogInService } from '../services/log-in.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import { Subject }    from 'rxjs/Subject';
import { of }         from 'rxjs/observable/of';
import { UrlService } from './url.service';

@Injectable()
export class ClientApplicationService {
  caSubject: Subject<any> = new Subject<any>();

  constructor(
    private http: HttpClient,
    private url: UrlService,
    private router: Router,
    private route: ActivatedRoute,
    private logInService: LogInService) { }

	getData(): Observable<any> {
    let invitationId = this.route.snapshot.queryParams.invitationId;
    let applicationId = this.route.snapshot.queryParams.applicationId;
    let signature = this.route.snapshot.queryParams.signature;

    if (invitationId) {
      let o:Observable<any>;
      return new Observable(obs => {
        let obs1 = this.logInService.getInvitation(invitationId, signature);

        obs1.subscribe(i => {
          this.http.get(this.url.apiUrl() + "/clientapplication" + (i.clientApplicationId ?  ("?applicationId=" + i.clientApplicationId) : "" ))
            .subscribe(data => {
                obs.next(data);
                this.updateCA(data);
              }
            );

        }, error => {
          this.http.get(this.url.apiUrl() + "/clientapplication")
            .subscribe(data => obs.next(data));
        });
      });
    } else if (applicationId) {
      return this.http.get(this.url.apiUrl() + "/clientapplication?applicationId=" + applicationId);
    } else {
      return this.http.get(this.url.apiUrl() + "/clientapplication");
    }
	}

  getDataByApplicationId(applicationId): Observable<any> {
    return this.http.get(this.url.apiUrl() + "/clientapplication?applicationId=" + applicationId);
  }

	updateCA(ca) {
    this.caSubject.next(ca);
  }

}

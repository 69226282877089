import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material.module';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './/app-routing.module';
import { LoginComponent } from './login/login.component';
import { AccountComponent } from './account/account.component';
import { PasswordComponent } from './password/password.component';
import { ClientApplicationService } from './services/client-application.service';
import { DelegateService } from './services/delegate.service';
import { LogInService } from './services/log-in.service';
import { AccountService } from './services/account.service';
import { UrlService } from './services/url.service';
import { ActivateAccountComponent } from './activate-account/activate-account.component';
import { DelegateComponent } from './delegate/delegate.component';
import {CreateAmexAccountComponent} from "./create-amex-account/create-amex-account.component";
import {ExternalExpenseProviderService} from "./services/external-expense-provider.service";
import { CookieService } from "ngx-cookie-service";
import { UtilitiesService } from './shared/utilities.service';
import { AppVersionLowComponent } from './app-version-low/app-version-low.component';
import {TranslateWithSuffix} from "./shared/translate-with-suffix.pipe";

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        AccountComponent,
        PasswordComponent,
        ActivateAccountComponent,
        DelegateComponent,
        CreateAmexAccountComponent,
        AppVersionLowComponent,
        TranslateWithSuffix,
    ],
  imports: [
    BrowserModule,
    AppRoutingModule,
		FormsModule,
		BrowserAnimationsModule,
		MaterialModule,
		HttpClientModule,
		TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
		ClientApplicationService,
		LogInService,
		AccountService,
    DelegateService,
		UrlService,
    ExternalExpenseProviderService,
    CookieService,
    UtilitiesService,
    TranslateWithSuffix,
	],
  exports: [
    TranslateWithSuffix,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

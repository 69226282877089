import { Component, OnInit } from '@angular/core';
import { ClientApplicationService } from '../services/client-application.service';
import { DelegateService } from '../services/delegate.service';
import { AccountService } from '../services/account.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-delegate',
  templateUrl: './delegate.component.html',
  styleUrls: ['./delegate.component.css']
})
export class DelegateComponent implements OnInit {

  authenticationToken = null;
  clientApplication = null;
  clientId;
  privilegeString = null;
  privileges = [];
  isBusy = false;
  returnUri;
  delegatedApplication = null;
  user = null;


  constructor(
    private caService: ClientApplicationService,
    private delegateService: DelegateService,
    private accountService: AccountService,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit() {

    this.isBusy = true;
    this.clientId = this.route.snapshot.paramMap.get('clientId');
    this.privilegeString = this.route.snapshot.queryParams.p;
    this.returnUri = this.route.snapshot.queryParams.returnUri;
    this.privileges = this.privilegeString.split(',');

    console.log("delegate.component: onInit");
    console.log("Got return uri", this.returnUri);

    this.caService.getData().subscribe(ca => {

      this.clientApplication = ca;
      this.isBusy = false;
      this.authenticationToken = localStorage.getItem("authentication-token-" + ca.applicationId);

      console.log("got token", this.authenticationToken);
      console.log("got params", {clientId: this.clientId, privs: this.privilegeString});

      if (!this.authenticationToken) {
        console.log("Sending return uri", this.returnUri);
        this.router.navigate(['/login'], { queryParams: { targetUri: "/#/delegate/" + this.clientId + "?p=" + this.privilegeString + "&returnUri=" + encodeURIComponent(this.returnUri).toString() } });
      } else {

        this.isBusy = true;
        this.accountService.getMe(this.authenticationToken).subscribe(user => {

          this.isBusy = false;
          this.user = user;

          console.log("got user", user);

        });

        this.delegateService.getApplication(this.clientId, this.authenticationToken).subscribe(application => {

          console.log("got application", application);
          this.delegatedApplication = application;
          localStorage.removeItem("authentication-token-" + ca.applicationId);

        });
      }

    });

  }

  grantAccess(): void {

    this.isBusy = true;

    console.log("deleate", {cid: this.clientId, p: this.privilegeString, t: this.authenticationToken});
    this.delegateService.grantAccess(this.clientId, this.privilegeString, this.authenticationToken).subscribe(response => {

      console.log("result", response);
      console.log("result", decodeURIComponent(this.returnUri));

      var ret = decodeURIComponent(this.returnUri);

      if (ret.indexOf("?") !== -1) {
        ret += "&code=" + response.authorizationCode;
      } else {
        ret += "?code=" + response.authorizationCode;
      }

      location.replace(ret);

    });

  }

  cancel(): void {

    location.replace(this.returnUri);

  }

}

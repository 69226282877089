<div fxFlex>
  <div>
    <h5 class="text-left">
      {{'CREDIT_CARD_NOT_ACTIVATED' | translate}}
      <br/><br/>
      {{'LEAVING_AMEX_EXPENSE_INFO' | translate}}
      <br/><br/>
    </h5>
  </div>
  <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between">
    <button mat-button fxFlexOrder.xs="2" (click)="checkDeviceForRedirecting()"><u>{{'CANCEL' | translate}}</u></button>
    <button mat-raised-button color="primary" (click)="goToAmericanExpress()">{{'CONTINUE' | translate}}</button>
  </div>
</div>

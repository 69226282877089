<mat-progress-bar mode="indeterminate" *ngIf="isBusy"></mat-progress-bar>
<div class="login-form">
  <div>
    <h4>{{ 'FORGOT_PASSWORD' | translate }}</h4>
    <p class="sub-heading" [innerHTML]="'FORGOT_PASSWORD_INFO' | translate"></p>

    <div class="alert info" *ngIf="emailSent">
      <div>{{ 'FORGOT_PASSWORD_EMAIL_SENT' | translate:{email: email} }}</div>
    </div>

    <div class="alert error" *ngIf="lastError">
      <div *ngIf="lastError.code === 'email-not-found'">{{ 'NO_ACCOUNT_WITH_THAT_EMAIL' | translate }}</div>
      <div *ngIf="lastError.code === 'error-sending-reset-mail'">{{ 'UNKNOWN_ERROR' | translate }}</div>
      <div *ngIf="lastError.code === 'account-not-activated'">{{ 'ACCOUNT_NOT_ACTIVATED' | translate }}</div>
    </div>

    <mat-form-field floatLabel="never">
      <input matInput placeholder="{{ 'EMAIL_ADDRESS' | translate }}" type="email" (keyup.enter)="requestPassword(email)" [disabled]="isBusy" [(ngModel)]="email">
      <mat-hint>{{'EMAIL_ADDRESS_2' | translate}}</mat-hint>
    </mat-form-field>
  </div>
  <div>
    <div class="form-buttons" fxLayout="row" fxLayoutAlign="end center">
      <button mat-button routerLink="/" [disabled]="isBusy">{{ 'TO_START_PAGE' | translate }}</button>
      <button mat-raised-button color="primary" [disabled]="isBusy" (click)="requestPassword(email)">{{ 'REQUEST_PASSWORD' | translate }}</button>
    </div>
  </div>
</div>

import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Subject }    from 'rxjs/Subject';
import { of }         from 'rxjs/observable/of';
import { UrlService } from './url.service';
import { map, catchError } from 'rxjs/operators';
import { UtilitiesService } from '../shared/utilities.service';


let httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class AccountService {

  constructor(private http: HttpClient, private url: UrlService, private utilitiesService: UtilitiesService) { }

  setClientApplicationHeader(applicationId: string){
    httpOptions.headers = httpOptions.headers.set('X-Findity-ClientApplicationId', applicationId);
  }

  getMe(token: string): Observable<any> {
     httpOptions.headers = httpOptions.headers.set('Authorization', this.utilitiesService.getEncryptedToken(token));
     return this.http.get(this.url.apiUrl() + "/me", httpOptions).pipe(map((user: any) => {

       user.iconUrl = this.url.apiUrl() + "/images/icons/no_profile_image.png";

       if (user.content.length > 0) {
         user.iconUrl = user.content[0].url;
       }

       return user;

     }));

  }

  createAccount(user: object): Observable<any> {
		return this.http.post(this.url.apiUrl() + "/person", user, httpOptions);
	}

	activateAccount(activationEmail: String, activationCode: String): Observable<any> {
		return this.http.post(this.url.apiUrl() + "/auth/activate?activationEmail=" + activationEmail + "&activationCode=" + activationCode, {}, httpOptions);
	}

	activateAccountWithAdditionalRegistrationProcess(email: string): Observable<any> {
    return this.http.post(this.url.apiUrl() + "/auth/activate?email=" + email, {}, httpOptions);
  }

	activateAccountWithPendingActivation(activationId: String, firstName: String, lastName: String, password: String): Observable<any> {
		return this.http.post(this.url.apiUrl() + "/auth/activate?activationId=" + activationId, { firstName: firstName, lastName: lastName, password: password }, httpOptions);
	}

	resendActivationCode(activationEmail: String): Observable<any> {
		return this.http.post(this.url.apiUrl() + "/auth/activate/resend?activationEmail=" + activationEmail, {}, httpOptions);
	}

	emailFromImplicitReceipt(): String {
		return sessionStorage.getItem("identifier");
	}

	mobilePhoneFromImplicitReceipt(): String {
		return sessionStorage.getItem("identifier");
	}

	lockEmailFromImplicitReceipt(): Boolean {

		var implicitReceiptReference = sessionStorage.getItem("implicitReceiptReference");
		var identifier = sessionStorage.getItem("identifier");
		var identifierType = sessionStorage.getItem("identifierType");

		return (implicitReceiptReference !== undefined && implicitReceiptReference !== null && identifier !== undefined && identifier !== null && identifierType === "EMailAddress");

	}

	lockMobilePhoneFromImplicitReceipt(): Boolean {

		var implicitReceiptReference = sessionStorage.getItem("implicitReceiptReference");
		var identifier = sessionStorage.getItem("identifier");
		var identifierType = sessionStorage.getItem("identifierType");

		return (implicitReceiptReference !== undefined && implicitReceiptReference !== null && identifier !== undefined && identifier !== null && identifierType === "TelephoneNumber");

	}

	hasImplicitReceiptReference(): Boolean {

		var implicitReceiptReference = sessionStorage.getItem("implicitReceiptReference");
		return (implicitReceiptReference !== undefined && implicitReceiptReference !== null);

	}

	implicitReceiptReference(): String {

		return sessionStorage.getItem("implicitReceiptReference");

	}

	approves(): Boolean {

		var approves = sessionStorage.getItem("approves");
		return (approves === "true");

	}

}

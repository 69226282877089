<mat-progress-bar mode="indeterminate" *ngIf="isBusy"></mat-progress-bar>
<form #loginForm="ngForm">
  <div class="login-form">
    <div>
      <h4 *ngIf="step !== loginSteps.LOGIN_SELECT_IDP">{{ 'LOG_IN' | translate }}</h4>
      <h4 *ngIf="step === loginSteps.LOGIN_SELECT_IDP">{{ 'SELECT_IDP' | translate }}</h4>

      <div class="alert error" *ngIf="step === loginSteps.LOGIN_PASSWORD && lastError">
        <div
          *ngIf="this.lastError.code == 'login-attempt-threshold-exceeded'">{{ 'LOGIN_ATTEMPT_THRESHOLD_EXCEEDED' | translate:{minutes: lastError.waitNumberOfMinutes} }}</div>
        <div
          *ngIf="this.lastError.code != 'login-attempt-threshold-exceeded'">{{ 'WRONG_LOGIN_OR_PASSWORD_OR_REGION' | translate }}</div>
      </div>

      <div class="alert error" *ngIf="step === loginSteps.VERIFICATION_CODE && lastError">
        <div *ngIf="lastError.code === 'invalid-verification-code'">{{ 'INVALID_VERIFICATION_CODE' | translate }}</div>
      </div>

      <div class="alert info" *ngIf="step === loginSteps.VERIFICATION_CODE && lastInfo">
        <div *ngIf="lastInfo.code === 'two-factor-authorization-required'">{{ 'ENTER_SECURITY_CODE' | translate }}</div>
      </div>

      <div class="alert error" *ngIf="validationErrors?.length > 0">
        <div *ngFor="let e of validationErrors">{{e}}</div>
      </div>

      <div class="alert info" *ngIf="disableUserName === true">
        <div>{{ 'ENTER_PASSWORD_INFO_INACTIVITY' | translate }}</div>
      </div>

      <div class="alert error" *ngIf="clientApplication?.settings?.general?.showOAuthMessage">
        <div
          *ngIf="step === loginSteps.LOGIN_USERNAME || step === loginSteps.LOGIN_PASSWORD">{{ 'OAUTH_AUTHENTICATION_EXTERNAL_APP' | translateWithSuffix: clientApplication.name | async }}</div>
      </div>

      <div [hidden]="step !== loginSteps.LOGIN_USERNAME && step !== loginSteps.LOGIN_PASSWORD" class="field-wrapper">
        <div fxLayout="row">
          <div fxFlex="40px" style="padding: 12px 0 24px 0" fxHide.xs>
            <mat-icon>email</mat-icon>
          </div>
          <div fxFlex>
            <mat-form-field style="display: block">
              <input matInput [autofocus]="step === loginSteps.LOGIN_USERNAME"
                     placeholder="{{ 'EMAIL_ADDRESS' | translate }}" type="email"
                     (change)="step = loginSteps.LOGIN_USERNAME" (keyup)="onUsernameKeyUp($event, username)"
                     [disabled]="isBusy || disableUserName" [(ngModel)]="username" name="username"
                     autocomplete="username">
              <mat-hint>{{'EMAIL_ADDRESS_2' | translate}}</mat-hint>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div [hidden]="step !== loginSteps.LOGIN_PASSWORD" class="field-wrapper password-field">
        <div fxLayout="row">
          <div fxFlex="40px" style="padding: 12px 0 24px 0" fxHide.xs>
            <mat-icon>vpn_key</mat-icon>
          </div>
          <div fxFlex>
            <mat-form-field style="display: block">
              <input matInput [autofocus]="step === loginSteps.LOGIN_PASSWORD"
                     placeholder="{{ 'PASSWORD' | translate }}"
                     type="password" [disabled]="isBusy" (keyup.enter)="logIn(username, password)"
                     [(ngModel)]="password"
                     name="password" autocomplete="current-password">
              <mat-hint align="start">{{ 'PASSWORD' | translate }}</mat-hint>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div *ngIf="step === loginSteps.LOGIN_SELECT_IDP" class="field-wrapper">
        <div fxLayout="row">
          <div fxFlex>
            <mat-form-field style="display: block" floatLabel="always">
              <mat-label>{{ 'SELECT_IDP' | translate }}</mat-label>
              <mat-select [(ngModel)]="loginUrlId" (selectionChange)="selectIdp(loginUrlId)" placeholder="{{'SELECT_IDP' | translate }}" name="idp" [disabled]="isBusy">
                <mat-option *ngFor="let option of loginUrls" [value]="option.id">
                  {{ option.name }}
                </mat-option>
              </mat-select>
              <mat-hint align="start">{{'IDP' | translate}}</mat-hint>
            </mat-form-field>
          </div>
        </div>
      </div>

      <!--
      <div fxLayout="row">
        <div fxFlex="40px" style="padding: 20px 0">
          <mat-icon>vpn_key</mat-icon>
        </div>
        <div fxFlex>
          <mat-form-field style="display: block">
            <input matInput placeholder="{{ 'PASSWORD' | translate }}" type="password" [disabled]="isBusy" (keyup.enter)="logIn(username, password)"  [(ngModel)]="password">
          </mat-form-field>
        </div>
      </div>
    </div> -->

      <div *ngIf="step === loginSteps.VERIFICATION_CODE">
        <div fxLayout="row" class="form-field-wrapper">
          <div fxFlex="40px" style="padding: 20px 0">
            <mat-icon>security</mat-icon>
          </div>
          <div fxFlex>
            <mat-form-field style="display: block">
              <input matInput autofocus #securityCodeInput placeholder="{{ 'SECURITY_CODE' | translate }}" type="text"
                     [disabled]="isBusy || (lastError && lastError.code === 'invalid-verification-code')"
                     [(ngModel)]="securityCode" name="securityCode">
            </mat-form-field>
          </div>
        </div>
      </div>

      <div *ngIf="step === loginSteps.PHONE_NUMBER">
        <!-- <div fxLayout="row">
          <div fxFlex="40px" style="padding: 20px 0">
            <mat-icon>phone</mat-icon>
          </div>
          <div fxFlex>
            <mat-form-field style="display: block">
              <input matInput autofocus #phoneNumberInput placeholder="{{ 'PHONE_NUMBER' | translate }}" type="text" [disabled]="isBusy" [(ngModel)]="phoneNumber">
            </mat-form-field>
          </div>
        </div> -->
      </div>
    </div>

    <div id="loginFormButtonsSection">
      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="space-between" class="form-buttons">
        <div fxFlex="50" id="formButtonsFirstSection">
          <button id="backButton" type="submit" mat-raised-button color="primary" [disabled]="isBusy"
                  *ngIf="step === loginSteps.LOGIN_SELECT_IDP"
                  (click)="step = loginSteps.LOGIN_USERNAME; loginUrlId = null;">{{ 'BACK_TO_LOG_IN' | translate }}</button>
          <div>
            <button id="forgotPasswordButton" type="button" mat-button
                    (click)="gotoForgotPassword(username, clientApplication.applicationId )"
                    [disabled]="isBusy" *ngIf="step === loginSteps.LOGIN_PASSWORD">{{ 'FORGOT_PASSWORD'| translate }}
            </button>
          </div>
        </div>
        <div fxFlex="50" style="text-align: right" fxFlexOrder.xs="2" id="formButtonsSecondSection">
          <button id="loginButton" type="submit" mat-raised-button color="primary"
                  [disabled]="isBusy || (step === loginSteps.LOGIN_PASSWORD && (!password || !username))"
                  *ngIf="step === loginSteps.LOGIN_PASSWORD"
                  (click)="logIn(username, password)">{{ 'LOG_IN' | translate }}</button>
          <button id="nextButton" type="submit" mat-raised-button color="primary"  [disabled]="isBusy || !username"
                  *ngIf="step === loginSteps.LOGIN_USERNAME"
                  (click)="checkUsername(username)">{{ 'NEXT' | translate }}</button>
          <button id="verificationCodeButton" type="submit" mat-raised-button color="primary" [disabled]="isBusy"
                  *ngIf="step === loginSteps.VERIFICATION_CODE && (!lastError || lastError.code !== 'invalid-verification-code')"
                  (click)="step === loginSteps.LOGIN ? logIn(username, password) : validateSecurityCode(securityCode)">{{ (step === loginSteps.LOGIN ? 'LOG_IN' : 'VERIFY') | translate }}</button>
          <button id="phoneConfirmationButton" type="button" mat-button color="primary" [disabled]="isBusy"
                  *ngIf="step === loginSteps.VERIFICATION_CODE && lastError && lastError.code === 'invalid-verification-code'"
                  (click)="resetLogin()">{{ 'BACK_TO_LOG_IN' | translate }}</button>
          <!-- <button type="submit" mat-raised-button color="primary" [disabled]="isBusy" *ngIf="step === loginSteps.PHONE_NUMBER" (click)="validatePhoneNumber(phoneNumber)">{{ 'VERIFY' | translate }}</button> -->
        </div>
      </div>

      <div fxLayout="row" fxLayoutAlign="space-between center"
           *ngIf="step !== loginSteps.LOGIN_SELECT_IDP && (clientApplication && clientApplication.settings != null && clientApplication.settings.account.disableCreate !== true) || connectedClientApplications !== null"
           style="padding: 2px 0; margin-top: 10px;">
        <button id="createAccountButton" type="button" style="font-size: 15px;" mat-button (click)="gotoCreateAccount()"
                [disabled]="isBusy"
                *ngIf="clientApplication && clientApplication.settings != null && clientApplication.settings.account.disableCreate !== true">{{ 'CREATE_ACCOUNT' | translate }}</button>
        <div></div>
        <mat-form-field *ngIf="connectedClientApplications !== null" id="regionSelector">
          <mat-select [(ngModel)]="targetCA" (selectionChange)="loadClientApplication()" name="region"
                      (ngModelChange)="setCAIdCookie()" placeholder="{{ 'REGION' | translate}}">
            <mat-option *ngFor="let ca of connectedClientApplications"
                        [value]="ca">{{ 'COUNTRY_' + ca.name | translate }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div fxLayout="row" class="form-buttons trial" *ngIf="(onlineWizardEnabled === true && (step !== loginSteps.LOGIN_SELECT_IDP && step !== loginSteps.LOGIN_PASSWORD || (step === loginSteps.LOGIN_PASSWORD && wrongLoginPasswordRegion === true)))">
        <div fxFlex="100" style="text-align: center;">
          <button id="trialPeriodButton" type="button" mat-button (click)="signUpForTrialPeriod()"
                  [disabled]="isBusy">{{ 'START_TRIAL_PERIOD' | translate }}</button>
        </div>
      </div>

    </div>
  </div>
</form>

import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Subject }    from 'rxjs/Subject';
import { of }         from 'rxjs/observable/of';
import { UrlService } from './url.service';
import { UtilitiesService } from '../shared/utilities.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class DelegateService {

  constructor(private http: HttpClient, private url: UrlService, private utilitiesService: UtilitiesService) { }

  addAuthorizationHeader(token: string){
    if(!httpOptions.headers.get('Authorization')) {
      httpOptions.headers = httpOptions.headers.set('Authorization', this.utilitiesService.getEncryptedToken(token));
    }
  }
  getApplication(clientId: string, token: string): Observable<any> {
    this.addAuthorizationHeader(token);
    return this.http.get(this.url.apiUrl() + "/applications/" + clientId, httpOptions);
  }

  grantAccess(clientId: string, privileges: string, token: string): Observable<any> {
    this.addAuthorizationHeader(token);
    return this.http.post(this.url.apiUrl() + "/me/applications/" + clientId + "/grant?privileges=" + privileges, {}, httpOptions);
  }

}

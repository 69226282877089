import { Component, OnInit } from '@angular/core';
import { LogInService } from '../services/log-in.service';
import { AppComponent } from '../app.component';
import { ClientApplicationService } from '../services/client-application.service';
import { AccountService } from '../services/account.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UrlService } from '../services/url.service';
import {UtilitiesService} from '../shared/utilities.service';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountComponent implements OnInit {

	isBusy = false;
	validationErrors = [];
	clientApplication = null;
	invite = {};
	inviteRequired = false;
	inviteRequiredButNotFound = false;
	lockEmail = false; // This is locked if implicitReceiptReference is used with email identification
	lockMobilePhone = false; // This is locked if implicitReceiptReference is used with phone identification
	hasApproved = false; // can be set to true if implicitReceiptReference is used and user has approved from there
	user = {email: null, firstName: null, lastName: null, inviteId: null, implicitReceiptReference: null,
    mobilePhone: null, password: null, status: null, organization: null};
  organizations = [];
  orgIdParameter: string = null;

  constructor(
		private logInService: LogInService,
		private urlService: UrlService,
		private caService: ClientApplicationService,
		private accountService: AccountService,
		private route: ActivatedRoute,
    private appComponent: AppComponent,
		private router: Router,
    private utilities: UtilitiesService) { }

  ngOnInit() {
    this.caService.getData().subscribe(ca => {
      this.clientApplication = ca;
      this.accountService.setClientApplicationHeader(this.clientApplication.applicationId);
      this.orgIdParameter = this.route.snapshot.queryParams.orgId;
      console.log('##== orgId', this.orgIdParameter);

      //TODO: temporary way of setting countries
      if (ca.settings.account.organizations) {
        this.organizations = this.clientApplication.settings.account.organizations;

        if(this.orgIdParameter) {
          this.user.organization = this.organizations.find(org => org.id === this.orgIdParameter);
        } else {
          this.user.organization = this.organizations.find(org => org.name === 'SWEDEN' || org.name === 'FINLAND');
        }
      }

      let invitationId = this.route.snapshot.queryParams.invitationId;
      let signature = this.route.snapshot.queryParams.signature;

      if (ca.settings.account.disableCreate || (ca.settings.account.disableCreateButNotAmex && invitationId != undefined)) {
        this.inviteRequired = true;

        this.logInService.getInvitation(invitationId, signature).subscribe(i => {
          this.invite = i;
          this.user.email = i.email;
          this.user.firstName = i.firstName;
          this.user.lastName = i.lastName;
          this.user.status = i.status;
          this.user.inviteId = invitationId;
        }, error => {
          this.inviteRequiredButNotFound = true;
        });
      }
    });

		this.urlService.storeUrlState();

		if (this.accountService.lockEmailFromImplicitReceipt()) {
			this.user.email = this.accountService.emailFromImplicitReceipt();
			this.lockEmail = true;
		}

		if (this.accountService.lockMobilePhoneFromImplicitReceipt()) {
			this.user.mobilePhone = this.accountService.mobilePhoneFromImplicitReceipt();
			this.lockMobilePhone = true;
		}

		if (this.accountService.approves()) {
			this.hasApproved = true;
		}

		if (this.accountService.hasImplicitReceiptReference()) {
			this.user.implicitReceiptReference = this.accountService.implicitReceiptReference();
		}

  }

	createAccount(): void {

		this.isBusy = true;

		if (this.user.status === "PENDING_ACTIVATION") {

			this.accountService.activateAccountWithPendingActivation(this.user.inviteId, this.user.firstName, this.user.lastName, this.user.password).subscribe(x => {
				this.validationErrors = [];
				console.log("ACTIVATE ACCOUNT", x);
				var token = x.authenticationToken;
        var storedIsApp = sessionStorage.getItem("isApp");
				//check if there is a need to open a window to connect Amex CC -- this solution should only work on Web or in case that user is doing this from our mobile app
        if (this.clientApplication.settings.account.additionalRegistrationProcess === true
          && this.clientApplication.settings.account.creditCardRequiredForRegistration === true && (this.utilities.detectDeviceTypeByBrowser() === 'DESKTOP' || storedIsApp === 'true')) {
          this.router.navigate(['/amex-activate-card'], { queryParams: { authenticationToken: x.authenticationToken, applicationId: this.clientApplication.applicationId } });
        } else {
          if (token !== null && token !== undefined) {
            console.log("account created, redirecting");

            this.isBusy = false;
            this.utilities.checkDeviceForRedirecting(this.clientApplication, token);

          } else {
            this.isBusy = false;
          }
        }

			}, error => {
				console.log(error);
				this.validationErrors = error.error.validationErrors;
				this.isBusy = false;
			});

		} else {

			this.accountService.createAccount(this.user).subscribe(x => {
				this.validationErrors = [];

				console.log("CREATE ACCOUNT ", x);
				var token = x.authenticationToken;

        if (token !== null && token !== undefined) {
					console.log("account created, redirecting")
					window.location.href = this.clientApplication.redirectUrl + "?authenticationToken=" + this.utilities.getEncryptedToken(token);
        } else if (x.account.status === "INACTIVE") {
					console.log("inactive account, redirecting to activation page")
					this.router.navigate(['/activate-account'], { queryParams: { activationEmail: this.user.email, applicationId: this.clientApplication.applicationId } });
				}


			}, error => {
				console.log(error.error);
				this.validationErrors = error.error.validationErrors;
				this.isBusy = false;
			});

		}
	}

  gotoLogin(): void {
    this.router.navigate(["/login"]);
  }
}

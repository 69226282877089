import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {UrlService} from "../services/url.service";
import {ClientApplicationService} from "../services/client-application.service";
import {AccountService} from "../services/account.service";
import {ExternalExpenseProviderService} from "../services/external-expense-provider.service";
import {UtilitiesService} from '../shared/utilities.service';

@Component({
  selector: 'app-create-amex-account',
  templateUrl: './create-amex-account.component.html',
  styleUrls: ['./create-amex-account.component.css']
})
export class CreateAmexAccountComponent implements OnInit {

  providerDefinitionId: string;
  url: string;
  clientApplication: any;
  token: string;
  user: any;
  validationErrors = [];

  constructor(private route: ActivatedRoute,
              private urlService: UrlService,
              private caService: ClientApplicationService,
              private accountService: AccountService,
              private providerService: ExternalExpenseProviderService,
              private utilitiesService: UtilitiesService) {
  }

  ngOnInit() {
    this.providerDefinitionId = '1'; // TODO: change from hardcoded
    this.token = this.route.snapshot.queryParams.authenticationToken;
    let clientApplicationId = this.route.snapshot.queryParams.applicationId;
    if(clientApplicationId !== undefined) {
      this.caService.getDataByApplicationId(clientApplicationId).subscribe(ca => {
        this.clientApplication = ca;
        this.accountService.setClientApplicationHeader(this.clientApplication.applicationId);
      });
    } else {
      this.caService.getData().subscribe(ca => {
        this.clientApplication = ca;
        this.accountService.setClientApplicationHeader(this.clientApplication.applicationId);
      });
    }
    this.accountService.getMe(this.token).subscribe(user => {
      this.user = user;
    });

  }

  goToAmericanExpress() {
    this.url = this.urlService.apiUrl() + '/expenses/external/providerdefinitions/' + this.providerDefinitionId + '/connect?authenticationToken=' + this.utilitiesService.getEncryptedToken(this.token);
    let popupWindow = window.open(this.url, 'connectopenbanking', 'height=400,width=500');

    let timer = setInterval(() => {
      if (popupWindow.closed) {
        clearInterval(timer);
        this.checkProviders();
      }
    }, 500);

  }

  checkProviders(): any {
    let providers = [];

    this.providerService.getMyProviders(this.token).subscribe(data => {
      providers = data;
      if (providers[ "providers" ].length > 0) {
        this.completeAccountActivation();
      } else {
        console.log('card activation cancelled...');
      }
    });

    return providers;
  }

  completeAccountActivation() {
    this.accountService.activateAccountWithAdditionalRegistrationProcess(this.user.email).subscribe(response => {
      this.validationErrors = [];
      if (response.result === 'success') {
        //if user is using mobile device, redirect him to AppStore or GooglePlayStore, otherwise, log him in
        this.utilitiesService.checkDeviceForRedirecting(this.clientApplication, this.token);
      }
    }, error => {
      console.log(error);
      this.validationErrors = error.error.validationErrors;
    });
  }

  checkDeviceForRedirecting(): void {
    this.utilitiesService.checkDeviceForRedirecting(this.clientApplication, this.token);
  }
}

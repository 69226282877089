<mat-progress-bar mode="indeterminate" *ngIf="isBusy"></mat-progress-bar>
<div class="login-form">
  <div>
    <h4>{{ 'ACTIVATE_ACCOUNT' | translate }}</h4>

    <div class="alert error" *ngIf="validationErrors.length > 0">
      <div *ngFor="let e of validationErrors">{{e}}</div>
    </div>

    <p *ngIf="!activationEmail">{{ 'NO_ACTIVATION_CODE' | translate }}</p>
    <div *ngIf="activationCompleted">
      <p>{{ 'YOUR_ACCOUNT_IS_ACTIVATED' | translate }}</p>
      <div fxLayout="row" class="form-buttons">
        <div fxFlex="100" style="text-align: right">
          <button mat-raised-button color="primary" (click)="checkDeviceForRedirecting()"
                  [disabled]="isBusy">{{ 'LOG_IN' | translate }}</button>
        </div>
      </div>
    </div>
    <div *ngIf="!activationCompleted && activationEmail">

      <div class="alert error" *ngIf="inviteRequiredButNotFound">
        <div>{{ 'NO_INVITE_EXISTS' | translate }}</div>
      </div>

      <p [innerHTML]="'ACTIVATION_MAIL_SENT' | translate:{activationEmail: activationEmail} "></p>

      <mat-form-field floatLabel="never">
        <input matInput placeholder="{{ 'ACTIVATION_CODE' | translate }}" [disabled]="isBusy"
               (keyup.enter)="activateAccount()" [(ngModel)]="activationCode">
      </mat-form-field>
    </div>
  </div>

  <div>
    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between" class="form-buttons">
      <div fxLayout.xs="row">
        <button mat-button [disabled]="isBusy" (click)="resendActivationCode()">{{ 'SEND_ACTIVATION_CODE' | translate }}</button>
        <button mat-button routerLink="/" [disabled]="isBusy">{{ 'TO_START_PAGE' | translate }}</button>
      </div>
      <div>
        <button mat-raised-button color="primary" [disabled]="isBusy" (click)="activateAccount()">{{ 'ACTIVATE_ACCOUNT' | translate }}</button>
      </div>
    </div>
  </div>

</div>

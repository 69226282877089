import { Component, OnInit } from '@angular/core';
import { LogInService } from '../services/log-in.service';
import { AccountService } from '../services/account.service';
import {ActivatedRoute, Router} from '@angular/router';
import { ClientApplicationService } from '../services/client-application.service';
import { UrlService } from '../services/url.service';
import {UtilitiesService} from '../shared/utilities.service';

@Component({
  selector: 'app-activate-account',
  templateUrl: './activate-account.component.html',
  styleUrls: ['./activate-account.component.css']
})
export class ActivateAccountComponent implements OnInit {

	isBusy = false;
	validationErrors = [];
	clientApplication = null;
	activationEmail = null;
	activationCode = null;
	activationCompleted = false;

  constructor(
		private logInService: LogInService,
		private urlService: UrlService,
		private caService: ClientApplicationService,
		private accountService: AccountService,
		private route: ActivatedRoute,
    private router: Router,
    private utilities: UtilitiesService) { }

  ngOnInit() {
    let applicationId = this.route.snapshot.queryParams.applicationId;

    if(applicationId !== undefined) {
      this.caService.getDataByApplicationId(applicationId).subscribe(ca => {
        this.clientApplication = ca;
        this.accountService.setClientApplicationHeader(this.clientApplication.applicationId);
      });
    } else {
      this.caService.getData().subscribe(ca => {
        this.clientApplication = ca;
        this.accountService.setClientApplicationHeader(this.clientApplication.applicationId);
      });
    }

		this.activationEmail = this.route.snapshot.queryParams.activationEmail;
		this.activationCode = this.route.snapshot.queryParams.activationCode;

		if (this.activationEmail && this.activationCode) {
			this.activateAccount();
		}

		this.urlService.storeUrlState();

  }

	activateAccount(): void {

		this.isBusy = true;

		this.accountService.activateAccount(this.activationEmail, this.activationCode).subscribe(x => {
			this.validationErrors = [];

      if (x.result === "success") {
        let isApp = sessionStorage.getItem("isApp");
        if (this.clientApplication !== undefined && this.clientApplication.settings.account.additionalRegistrationProcess === true
          && this.clientApplication.settings.account.creditCardRequiredForRegistration === true && (this.utilities.detectDeviceTypeByBrowser() === 'DESKTOP' || isApp === 'true')) {
          this.isBusy = false;
          this.router.navigate(['/amex-activate-card'], { queryParams: { authenticationToken: x.authenticationToken, applicationId: this.clientApplication.applicationId } });
        } else {
          this.activationCompleted = true;
        }
      }
			this.isBusy = false;

		}, error => {
			console.log(error);
			this.validationErrors = error.error.validationErrors;
			this.isBusy = false;
		});

	}

	resendActivationCode(): void {

		this.isBusy = true;

		this.accountService.resendActivationCode(this.activationEmail).subscribe(x => {
			this.isBusy = false;
			console.log("RESEND ACTIVATION CODE", x);
		}, error => {
			console.log(error);
			this.isBusy = false;
		});

	}

  checkDeviceForRedirecting(): void {
    this.utilities.checkDeviceForRedirecting(this.clientApplication, null);
  }
}

import { Injectable } from '@angular/core';
import {Router} from '@angular/router';

@Injectable()
export class UtilitiesService {

  constructor(private router: Router) { }

  checkDeviceForRedirecting(clientApplication: any, token: string): void {

    let storedIsApp = sessionStorage.getItem("isApp") === "true";
    console.log('isApp: ' + storedIsApp);
    if(!this.isNullOrUndefined(clientApplication) && !this.isNullOrUndefined(clientApplication.settings) && !this.isNullOrUndefined(clientApplication.settings.apps) && storedIsApp !== true) {
      let deviceType = this.detectDeviceTypeByBrowser();

      if (!this.isNullOrUndefined(clientApplication.settings.apps.android) && !this.isNullOrUndefined(clientApplication.settings.apps.android.downloadLink) && deviceType === 'ANDROID') {
        document.location.href = clientApplication.settings.apps.android.downloadLink;
      } else if (!this.isNullOrUndefined(clientApplication.settings.apps.ios) && !this.isNullOrUndefined(clientApplication.settings.apps.ios.downloadLink) && deviceType === 'IPHONE') {
        document.location.href = clientApplication.settings.apps.ios.downloadLink;
      } else {
        console.log("going with default web version..");
        this.redirectToUri(clientApplication, token, storedIsApp);
      }
    } else {
      console.log("missing definition for app links or user is using mobile app..going to redirectUri");
      this.redirectToUri(clientApplication, token, storedIsApp);
    }
  }

  redirectToUri(clientApplication: any, token: string, isApp: boolean): void{
    if (token !== null) {
      let storedIsApp = sessionStorage.getItem("isApp");
      sessionStorage.removeItem("isApp");
      if(storedIsApp === "true" && !this.isNullOrUndefined(clientApplication.settings.url.apiRootUrl)){
        console.log("Redirecting to app url: " + clientApplication.settings.url.apiRootUrl + "/auth/appredirect");
        location.replace(clientApplication.settings.url.apiRootUrl + "/auth/appredirect?authenticationToken=" + this.getEncryptedToken(token));
      } else {
        window.location.href = clientApplication.redirectUrl + "?authenticationToken=" + this.getEncryptedToken(token);
      }
    } else {
      console.log('Opening login page');
      this.router.navigate(['/login'], {queryParams: {applicationId: clientApplication.applicationId}});
    }
  }

  detectDeviceTypeByBrowser(): string {

    let userAgent = window.navigator.userAgent;
    console.log("Detected user agent: " + userAgent);

    let deviceType = 'DESKTOP';
    if (userAgent !== undefined && userAgent.toLowerCase().indexOf('android') > -1) {
      console.log("recognized android device.. ");
      deviceType = 'ANDROID';
    } else if ( userAgent !== undefined && userAgent.toLowerCase().indexOf('iphone') > -1){
      console.log("recognized ios device..");
      deviceType = 'IPHONE';
    }
    console.log("desktop device recognized..");
    return deviceType;
  }

  getEncryptedToken(token: string){
    return encodeURIComponent(token);
  }

  isNullOrUndefined(obj: any) {
    return obj === null || obj === undefined;
  }
}

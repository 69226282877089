import { Component, Input, OnInit, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { LogInService } from '../services/log-in.service';
import { ClientApplicationService } from '../services/client-application.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UrlService } from '../services/url.service';
import { AccountService } from '../services/account.service';
import {TranslateService} from '@ngx-translate/core';
import { CookieService } from "ngx-cookie-service";
import { UtilitiesService } from '../shared/utilities.service';

export enum LoginStep { LOGIN_USERNAME, LOGIN_SELECT_IDP, LOGIN_PASSWORD, VERIFICATION_CODE, PHONE_NUMBER };

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit, AfterViewInit {
  @ViewChild('securityCodeInput') securityCodeInput: ElementRef;

  public loginSteps = LoginStep;
	step : LoginStep = LoginStep.LOGIN_USERNAME;
	isBusy = false;
	disableUserName = false;
	lastError = null;
	lastInfo = null;
	clientApplication = null;
	username = null;
	password = null;
	securityCode = null;
	phoneNumber = null;
	validationErrors = [];
	loginUrls = [];
	loginUrlId = null;
  targetCA = null;
	isRegionSet : boolean = false;
	connectedClientApplications = null;
	appVersionLow : boolean = false;
  onlineWizardEnabled: boolean = false;
  wrongLoginPasswordRegion = false;

  private targetCAIdCookieName = 'Findity-RegionLocale';
  private fromCASystem = false;

	constructor(
		private logInService: LogInService,
		private accountService: AccountService,
		private urlService: UrlService,
		private caService: ClientApplicationService,
		private route: ActivatedRoute,
		private router: Router,
    private translate: TranslateService,
    private cookieService: CookieService,
    private utilitiesService: UtilitiesService) { }


	ngOnInit() {

    console.log("login.component: onInit");
		this.urlService.storeUrlState();
    const targetUri = sessionStorage.getItem('targetUri');
    const targetLoc = this.getLocation(targetUri);
    this.fromCASystem = targetLoc.hostname.includes('int.findity.net');

		this.caService.getData().subscribe(ca => {
			this.clientApplication = ca;

			this.logInService.setClientApplicationHeader(this.clientApplication.applicationId);

      // on login page, this part has to work only for Amex CA
      if(ca.settings.connectedClientApplications !== null && ca.settings.general !== null && ca.settings.general.showRegionField === true) {

        let browserLang = this.translate.currentLang;
        this.connectedClientApplications = ca.settings.connectedClientApplications;

        const cookieRegionLocale = this.cookieService.get(this.targetCAIdCookieName);
        if(cookieRegionLocale) {
          const target = this.connectedClientApplications.find(ca => ca.locale === cookieRegionLocale);
          this.targetCA = target || this.connectedClientApplications[0];
        } else if (ca.applicationId) {
          this.logInService.getCountryInfoFromIP().subscribe((response) => {
            console.log('received response for country info: ' + response.countryCode);
            this.assignRegionBasedOnIPAddressCountry(response.countryCode);
            if(!this.isRegionSet) {
              this.assignRegionBasedOnBrowserLanguage(browserLang);
            }
            if(!this.isRegionSet){
              console.log('region not set by any choice, proceed with default ca ' + this.clientApplication.appllicationId);
              this.targetCA = this.connectedClientApplications.find(ca => ca.clientApplicationId === ca.applicationId);
            }
          }, (error) => {
            console.log('error received while getting country info based on ip. proceeding with setting region based on browser lang');
            this.assignRegionBasedOnBrowserLanguage(browserLang);
            if(!this.isRegionSet){
              console.log('region not set by any choice, proceed with default ca ' + this.clientApplication.appllicationId);
              this.targetCA = this.connectedClientApplications.find(ca => ca.clientApplicationId === ca.applicationId);
            }
          });
        }
      }

			if (this.route.snapshot.queryParams.username) {
				this.username = this.route.snapshot.queryParams.username;
				this.disableUserName = false;
			} else if (localStorage) {
				this.username = localStorage.getItem("login-username-" + ca.applicationId);
			}

      //in case that region is selected based on cookie, it is needed to switch to corresponding clientApplication
      if( this.targetCA != null && this.clientApplication.applicationId !== this.targetCA.clientApplicationId){
        this.loadClientApplication();
      }

      this.checkVisibilitySignUpForTrialPeriodButton();
		});
	}

	ngAfterViewInit() {
		console.log('AfterViewInit');
		console.log(this.securityCodeInput);
  }

  public assignRegionBasedOnIPAddressCountry(countryCode: string){
	  let defaultRegion = null;
	  const regionIndex = this.connectedClientApplications.findIndex((cca) => cca.name.toLowerCase() === countryCode.toLowerCase());
    if(this.connectedClientApplications !== null && regionIndex > -1) {
      defaultRegion = this.connectedClientApplications[regionIndex];
      console.log('setting region based on country found by IP recognition: ' + countryCode + ' to ' + defaultRegion.clientApplicationId);
      console.log('##== this.connectedClientApplications', this.connectedClientApplications);
      this.targetCA = defaultRegion;
      this.loadClientApplication();
      this.isRegionSet = true;
    }
  }

  public assignRegionBasedOnBrowserLanguage(browserLang: string){
    let defaultRegion = null;
    let regionIndex = null;
    if( browserLang !== null && this.connectedClientApplications !== null
      && (regionIndex = this.connectedClientApplications.findIndex((cca) => cca.locale === browserLang) ) > -1 ) {
      defaultRegion = this.connectedClientApplications[regionIndex];
      console.log("setting region based on browser language: " + browserLang + ' to: ' + defaultRegion.clientApplicationId);
      this.targetCA = defaultRegion;
      this.loadClientApplication();
      this.isRegionSet = true;
    }
  }

  onUsernameKeyUp(event, username): void {
    if (event.key === "Enter" || event.key === "Tab") {
      this.checkUsername(username);
    }
  }

  checkUsername(username: String): void {

    this.isBusy = true;
    this.lastError = null;
    this.lastInfo = null;
    this.loginUrlId = null;

    //check for external login urls
    this.logInService.getExternalLoginUrls(username).subscribe(response => {

      let proceedWithCheckOfExternalUrls = true;
      if(response.loginUrls && response.loginUrls.length > 0 && this.targetCA != null){
         proceedWithCheckOfExternalUrls = response.loginUrls.findIndex((external) => external.clientApplicationId == this.targetCA.clientApplicationId ) > -1;
      }

      if(proceedWithCheckOfExternalUrls) {
        this.loginUrls = response.loginUrls;
        if ((response.loginUrls && response.loginUrls.length === 1) && !this.fromCASystem) {
          if (response.loginUrls[0].loginUrl) {
            this.selectIdp(response.loginUrls[0].id);
          } else {
            var caId = response.loginUrls[0].clientApplicationId;

            if (!caId) {
              caId = this.clientApplication.applicationId;
            }

            this.caService.getDataByApplicationId(caId).subscribe(ca => {
              this.clientApplication = ca;
              this.step = LoginStep.LOGIN_PASSWORD;
              this.isBusy = false;
              this.loginUrlId = response.loginUrls[0].id;

            });

          }
        } else if (response.loginUrls && response.loginUrls.length > 1 && !this.fromCASystem) {
          this.step = LoginStep.LOGIN_SELECT_IDP;
          this.isBusy = false;

        } else {
          this.step = LoginStep.LOGIN_PASSWORD;
          this.isBusy = false;
        }
      } else {
          this.step = LoginStep.LOGIN_PASSWORD;
          this.isBusy = false;
      }
    }, error => {
      console.log("getExternalLoginUrls error: " + error);
      this.isBusy = false;
    });
  }

  selectIdp(id: String): void {
    console.log("login.component: selectIdp " + id);
    this.isBusy = true;
    var loginUrl = this.loginUrls.find((el) => el.id === id);
    if( loginUrl == null && this.connectedClientApplications != null) {
      loginUrl = this.connectedClientApplications.find((el) => el.clientApplicationId === id);
    }

    this.caService.getDataByApplicationId(loginUrl.clientApplicationId).subscribe(ca => {

      this.clientApplication = ca;
      this.logInService.setClientApplicationHeader(this.clientApplication.applicationId);
      var storedIsApp = sessionStorage.getItem("isApp");
      var targetUri = sessionStorage.getItem("targetUri");
      var isApp = (storedIsApp === "true");
      var redirectUrl = isApp ? this.clientApplication.settings.url.apiRootUrl + "/auth/appredirect" : this.clientApplication.redirectUrl;

      if ((targetUri === undefined || targetUri === null) && isApp === false) {
        redirectUrl = this.clientApplication.redirectUrl;
      } else if (targetUri !== undefined && targetUri !== null) {
        redirectUrl = targetUri;
      }

      if (loginUrl) {

        console.log("login.component: using idp", loginUrl);

        if (loginUrl.externalLogoutUrl) {
          sessionStorage.setItem("externalLogoutUrl", loginUrl.externalLogoutUrl);
        }
      }

      if (loginUrl && loginUrl.loginUrl) {
        document.location.href = loginUrl.loginUrl + "?idpId=" + loginUrl.id + "&clientApplicationId=" + this.clientApplication.applicationId + "&targetUri=" + encodeURIComponent(redirectUrl);
      } else {
        this.step = LoginStep.LOGIN_PASSWORD;
        this.isBusy = false;
        this.loginUrlId = loginUrl.clientApplicationId;
      }

    });

  }

  setCAIdCookie(){
    this.cookieService.set(this.targetCAIdCookieName, this.targetCA.locale,2000, null, null, null, null);
  }

	logIn(username: String, password: String): void {

    console.log("login.component: logIn");
		this.isBusy = true;
		this.lastError = null;
		this.lastInfo = null;

		var storedIsApp = sessionStorage.getItem("isApp");
		var targetUri = sessionStorage.getItem("targetUri");
		var isApp = (storedIsApp === "true");
    var isSkipSSO = (sessionStorage.getItem("skipSSO") === "true");

		if(this.connectedClientApplications != null){
		  this.loginUrlId = this.clientApplication.applicationId;
    }

		//set the right caId in header
		this.logInService.setClientApplicationHeader(this.clientApplication.applicationId);
		this.logInService.logIn(username, password, isApp, this.loginUrlId, isSkipSSO).subscribe(x => {
			if (x.result === "success") {

				if (localStorage) {
					localStorage.setItem("login-username-" + this.clientApplication.applicationId, username as string);
				}

				this.redirectAfterLogin(targetUri, x, isApp);

			} else {
				this.isBusy = false;
			}
		}, error => {
			console.log(error.error);

			if (error.error.code === "account-not-activated") {
				this.router.navigate(['/activate-account'], { queryParams: { activationEmail: username, applicationId: this.clientApplication.applicationId } });
			} else if (error.error.code === "two-factor-authorization-required") {
				this.step = LoginStep.VERIFICATION_CODE;
				this.lastInfo = error.error;
      } else {
        this.lastError = error.error;

        if (error.error.code && error.error.code === 'user-unauthenticated') {
          this.wrongLoginPasswordRegion = true;
        }
      }

			this.isBusy = false;
		});

	}

	validateSecurityCode(securityCode: String) : void {
		this.isBusy = true;
		this.lastError = null;
		this.lastInfo = null;

		var storedIsApp = sessionStorage.getItem("isApp");
		var targetUri = sessionStorage.getItem("targetUri");
		var isApp = (storedIsApp === "true");

		this.logInService.validateSecurityCode(this.username, this.password, securityCode, isApp).subscribe(x => {
			if (x.result === "success") {

				if (localStorage) {
					localStorage.setItem("login-username-" + this.clientApplication.applicationId, this.username as string);
				}

				this.redirectAfterLogin(targetUri, x, isApp);

			} else {
				this.isBusy = false;
			}
		}, error => {
			console.log(error.error);

			if (error.error.code === "invalid-verification-code") {
				this.lastError = error.error;
				this.lastInfo = null;
				this.step = LoginStep.VERIFICATION_CODE;
			} else if (error.error.code === "missing-phone-number") {
				this.lastError = error.error;
				this.lastInfo = null;
				this.step = LoginStep.PHONE_NUMBER;
			}

			this.isBusy = false;
		});
	}

	resetLogin() : void {
		this.securityCode = "";
		this.step = this.loginSteps.LOGIN_USERNAME;
		this.lastError = null;
	}

	validatePhoneNumber() : void {
		this.isBusy = true;
		this.lastError = null;
		this.lastInfo = null;


		this.isBusy = false;
	}

	private redirectAfterLogin(targetUri: string, response: any, isApp: Boolean): void {

    console.log("login.component: redirectAfterLogin");
		sessionStorage.removeItem("isApp");
		sessionStorage.removeItem("targetUri");

		if (isApp === true) {
			console.log("Redirecting to app url: " + response.clientApplication.settings.url.apiRootUrl + "/auth/appredirect");
			location.replace(response.clientApplication.settings.url.apiRootUrl + "/auth/appredirect" + "?authorizationCode=" + encodeURIComponent(response.authorizationCode));
		} else if (targetUri !== undefined && targetUri !== null) {

			var targetLoc = this.getLocation(targetUri);
			var redirectLoc = this.getLocation(response.clientApplication.redirectUrl);

			console.log("targetLoc: " + targetLoc);
			console.log("redirectLoc: " + redirectLoc);

			let correctRedirect = targetLoc.hostname.indexOf(redirectLoc.hostname) === 0 || this.fromCASystem;

			if (!correctRedirect && response.clientApplication.extraUrls) {

				response.clientApplication.extraUrls.forEach( url => {
					let extraLoc = this.getLocation(url);
					if (targetLoc.hostname.indexOf(extraLoc.hostname) === 0) {
						correctRedirect = true;
					}
				})

			}

			if (!correctRedirect || targetUri.indexOf('authorizationCode=') > -1) {
        // Only permit redirect to clearly defined and trusted zones. Important!
			  console.log("Invalid redirect url, going to standard");
        location.replace(response.clientApplication.redirectUrl + "?authorizationCode=" + encodeURIComponent(response.authorizationCode));
			} else {
        if (!targetUri.startsWith("/#/delegate")) {
          if (targetUri.indexOf("?") === -1) {
            targetUri += "?authorizationCode=" + encodeURIComponent(response.authorizationCode);
          } else {
            targetUri += "&authorizationCode=" + encodeURIComponent(response.authorizationCode);
          }

          console.log("Redirecting to targetUri: " + targetUri);
          location.replace(targetUri);

        } else {
          localStorage.setItem("authentication-token-" + this.clientApplication.applicationId, response.authenticationToken);

          // Use router instead of location
          var route = targetUri.split('?')[0].substring(2);
          var queryParams = {};

          targetUri.split('?')[1].split('&').forEach(function(para) {
            var paraParts = para.split('=');
            queryParams[paraParts[0]] = paraParts[1];
          });

          console.log("Routing to: " + route);
          console.log("Query params", queryParams);

          this.router.navigate([route], { queryParams: queryParams });

        }
			}
    } else {
      if (this.fromCASystem) {
        location.replace(response.clientApplication.settings.url.uiRootUrl + '/admin/ca-stats?authorizationCode=' + encodeURIComponent(response.authorizationCode));
      } else {
        location.replace(response.clientApplication.redirectUrl + '?authorizationCode=' + encodeURIComponent(response.authorizationCode));
      }
    }
  }

  gotoForgotPassword(username: String, appId: String): void {
    var queryParams = { applicationId: appId };
    this.router.navigate(["/request-password/" + username], { queryParams: queryParams } );
  }

  gotoCreateAccount(): void {
	  if (this.connectedClientApplications != null ){
	    var queryParams: any = { applicationId: this.clientApplication.applicationId };
	    if (this.targetCA && this.targetCA.orgId) {
	      queryParams.orgId = this.targetCA.orgId;
      }
      this.router.navigate(["/create-account"], { queryParams: queryParams } );
    } else {
      this.router.navigate(["/create-account"] );
    }
  }

	private getLocation(href: string) {
		var l = document.createElement("a");
		l.href = href;
		return l;
	};

  loadClientApplication(){
    if(this.targetCA) {
      this.step = LoginStep.LOGIN_USERNAME;
      this.caService.getDataByApplicationId(this.targetCA.clientApplicationId).subscribe((response) => {
        this.clientApplication = response;
        this.isBusy = false;
        this.logInService.setClientApplicationHeader(this.clientApplication.applicationId);
        this.checkVisibilitySignUpForTrialPeriodButton();
      });
    }
  }

  checkVisibilitySignUpForTrialPeriodButton(){
    if(this.step === LoginStep.LOGIN_USERNAME || this.step === LoginStep.LOGIN_PASSWORD) {
      if (!this.utilitiesService.isNullOrUndefined(this.clientApplication.settings) &&
        !this.utilitiesService.isNullOrUndefined(this.clientApplication.settings.externalCompanyRegistration) &&
        !this.utilitiesService.isNullOrUndefined(this.clientApplication.settings.externalCompanyRegistration.enabled) &&
        !this.utilitiesService.isNullOrUndefined(this.clientApplication.settings.externalCompanyRegistration.enableMobileOnboarding) &&
        this.clientApplication.settings.externalCompanyRegistration.enabled === true && this.clientApplication.settings.externalCompanyRegistration.enableMobileOnboarding === true) {
        this.onlineWizardEnabled = true;
      }
    } else {
      this.onlineWizardEnabled = false;
    }
  }

  signUpForTrialPeriod(){
    console.log('redirecting to the online wizard');
    let redirectUrl = this.clientApplication.settings.url.uiRootUrl + '/organization/register';

    const storedIsApp = sessionStorage.getItem("isApp");
    if ( storedIsApp === "true" ) {
      redirectUrl = redirectUrl + "?isApp=true";
    }
    document.location.href = redirectUrl;
  }

}

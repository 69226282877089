import { Component, OnInit } from '@angular/core';
import { ClientApplicationService } from './services/client-application.service';
import { UrlService } from './services/url.service';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import {NavigationEnd, Router, RouterEvent} from "@angular/router";
import {logger} from "codelyzer/util/logger";
import {filter} from "rxjs/operators";
import { LANG_MAPPINGS } from "./language-mappings";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

	clientApplication = null;
	logoUrl = null;
	appComponentBusy = false;
	appLoaded = false;
	appVersionLow = false;

	bgIndex = 0;
	bgs = [
		"url(assets/images/bg4.jpg",
		"url(assets/images/bg5.jpeg)",
		"url(assets/images/bg2.jpeg)"
	];
  showSignUpLink = false;

	constructor(
		private caService: ClientApplicationService,
		private urlService: UrlService,
		private titleService: Title,
		private translate: TranslateService,
    private router: Router) { }


	ngOnInit() {
    console.info("app.component: onInit");

    this.caService.caSubject.subscribe((ca : any) => {
      this.clientApplication = ca;
      this.updateUI(ca);
    });

		var lang = this.translate.getBrowserCultureLang(); //navigator.language;

    let mappedLang = LANG_MAPPINGS.filter( item => item.shortCode === lang);

    if(mappedLang.length === 1){
      lang = mappedLang[0].targetCode;
    }

    if(lang.indexOf('-') > -1){
      let langPart = lang.split('-');
      lang = langPart[0] + '_' + langPart[1].toUpperCase();
    }

		console.log("Browser locale", lang);

    	this.urlService.storeUrlState();

		// this language will be used as a fallback when a translation isn't found in the current language
		this.translate.setDefaultLang('en_GB');

		 // the lang to use, if the lang isn't available, it will use the current loader to get them
		this.translate.use(lang || 'en_GB');

		this.caService.getData().subscribe(ca => {

			this.clientApplication = ca;

			let appVersion = sessionStorage.getItem("appVersion");
			let appPlatform = sessionStorage.getItem("appPlatform");
      if (appPlatform !== null && appPlatform !== "undefined"){
        appPlatform = appPlatform.toUpperCase()
      }
			let isApp = sessionStorage.getItem("isApp");

			if ( isApp ) {
				if (appPlatform === "IPHONE" || appPlatform === "IPAD" || appPlatform === "IOS")
				{
					console.log("Current ios version number:", appVersion, ", minimum app version:", ca.settings.apps.minAppVersionIos);
					if (!this.compareVersions(ca.settings.apps.minAppVersionIos, appVersion)) {
						this.appVersionLow = true;
					}
				}
				if (appPlatform === "ANDROID")
				{
					console.log("Current android version number:", appVersion, ", minimum app version:", ca.settings.apps.minAppVersionAndroid);
					if (!this.compareVersions(ca.settings.apps.minAppVersionAndroid, appVersion)) {
						this.appVersionLow = true;
					}
				}
				if (appPlatform === "UNDEFINED" || appPlatform === null){
					this.appVersionLow = true;
				}
			}
			//removed sessionStorage.removeItem("isApp"); since it is needed in redirect and cleared there
			sessionStorage.removeItem("appVersion");
			sessionStorage.removeItem("appPlatform");

			if (this.appVersionLow === true){
				this.appLoaded = true;
				this.router.navigate(['/app-version-low']);
			} else {
				if (!this.urlService.checkSsoRedirect(ca)) {
					this.appLoaded = true;
				}
			}
			this.updateUI(ca);
		});

    this.router.events.pipe(
      filter(e => e instanceof NavigationEnd)
    ).subscribe(e => {
      let url = this.router.routerState.snapshot.url;
      this.showSignUpLink = (url === '/' || url === '/login');
    });
	}

  updateUI(ca) {
    console.log('Updating UI');
    const favIcon = document.querySelector('#html-head-fav-icon') as HTMLLinkElement;
    const customStyling = document.querySelector('#html-head-custom-styling') as HTMLStyleElement;

    this.logoUrl = ca.settings.design.menuLogoUrl || ca.settings.url.apiRootUrl + '/images/' + ca.settings.design.logo;
    favIcon.href = ca.settings.design.favIconUrl || ca.settings.design.favIcon;

    const textOnAccentColor = ca.settings.design.textOnAccentColor || '#000000';
    console.log('ca design', ca.settings.design);

    // color: accent
    customStyling.innerHTML = 'a, a:visited, .mat-focused .mat-form-field-label, .mat-mdc-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary { color: ' + ca.settings.design.accentColor + ' !important; }';
    // background-color: accent
    customStyling.innerHTML += '.mat-fab.mat-primary, .mat-mini-fab.mat-primary, .mat-mdc-raised-button.mat-primary, .mat-progress-bar-fill::after, .mat-focused .mat-mdc-form-field-underline,  .mat-mdc-form-field-ripple, .mat-mdc-checkbox-checked.mat-accent .mat-mdc-checkbox-background, .mat-mdc-checkbox-indeterminate.mat-accent .mat-mdc-checkbox-background { background-color: ' + ca.settings.design.accentColor + ' !important; color: ' + textOnAccentColor + ' !important; } ';
    // background-color: darkAccent
    customStyling.innerHTML += '.mat-progress-bar-buffer { background-color: ' + ca.settings.design.darkAccentColor + ' !important; }';
    // other
    customStyling.innerHTML += '.mat-mdc-form-field { --mdc-filled-text-field-focus-active-indicator-color: ' + ca.settings.design.accentColor + '}';
    customStyling.innerHTML += ' .mat-mdc-progress-bar { --mdc-linear-progress-active-indicator-color: ' + ca.settings.design.darkAccentColor + ' !important; --mdc-linear-progress-track-color: ' + ca.settings.design.accentColor + '80 !important; }';

    this.titleService.setTitle(ca.name + ' - Enabled by Findity');
  }

	changeBg(): void {

		var html = document.querySelector("#html-element") as HTMLElement;

		this.bgIndex++;
		if (this.bgIndex >= this.bgs.length) {
			this.bgIndex = 0;
		}

		html.style.backgroundImage = this.bgs[this.bgIndex];

	}

	compareVersions(minVersion: String, currentVersion: String) {
		minVersion = minVersion.split('.').map( s => s.padStart(10) ).join('.');
		currentVersion = currentVersion.split('.').map( s => s.padStart(10) ).join('.');
		return minVersion <= currentVersion;
	}

}

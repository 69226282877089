<mat-progress-bar mode="indeterminate" *ngIf="isBusy"></mat-progress-bar>
<form #accountForm="ngForm">
<div class="login-form">
  <div>
    <h4>{{ 'CREATE_ACCOUNT' | translate }}</h4>
    <div class="alert error" *ngIf="inviteRequiredButNotFound">
      <div>{{ 'NO_INVITE_EXISTS' | translate }}</div>
    </div>
    <div *ngIf="inviteRequiredButNotFound">
      <button id="loginButton" type="button" mat-button
              (click)="gotoLogin()"
              [disabled]="isBusy">{{ 'ALREADY_HAVE_ACCOUNT_LOGIN' | translate }}
      </button>
    </div>

    <div class="alert error">
      <div *ngFor="let e of validationErrors">{{e}}</div>
    </div>

    <div *ngIf="inviteRequiredButNotFound === false">

      <div fxLayout="row">
        <mat-form-field fxFlex="50" style="margin-right: 10px" floatLabel="never">
          <input matInput placeholder="{{ 'FIRST_NAME' | translate }}" value="{{invite.firstName}}" name="firstName" [disabled]="isBusy" [(ngModel)]="user.firstName" autocomplete="off" required>
        </mat-form-field>
        <mat-form-field fxFlex="50" floatLabel="never">
          <input matInput placeholder="{{ 'LAST_NAME' | translate }}" value="{{invite.lastName}}" name="lastName" [disabled]="isBusy" [(ngModel)]="user.lastName" autocomplete="off" required>
        </mat-form-field>
      </div>
      <mat-form-field floatLabel="never">
        <input matInput placeholder="{{ 'EMAIL_ADDRESS' | translate }}" type="email" value="{{invite.email}}" name="email" [disabled]="isBusy || inviteRequired || lockEmail" [(ngModel)]="user.email" autocomplete="email" required>
      </mat-form-field>

      <mat-form-field floatLabel="never">
        <input matInput placeholder="{{ 'PASSWORD' | translate }}" type="password" name="pasword" [disabled]="isBusy" [(ngModel)]="user.password" required>
        <span matSuffix matTooltip="{{ 'CREATE_ACCOUNT_PAGE.PASSWORD_INFO' | translate }}"><mat-icon>info</mat-icon></span>
      </mat-form-field>

      <mat-form-field *ngIf="clientApplication && clientApplication.settings.account.disablePersonalNumber !== true" floatLabel="never">
        <input matInput placeholder="{{ 'CREATE_ACCOUNT_PAGE.PERSONAL_ID' | translate }}" [disabled]="isBusy" name="identifier" [(ngModel)]="user.identifier">
        <span matSuffix matTooltip="{{ 'CREATE_ACCOUNT_PAGE.PERSONAL_ID_INFO' | translate }}"><mat-icon>info</mat-icon></span>
      </mat-form-field>

      <mat-form-field *ngIf="clientApplication && clientApplication.settings.account.disableMobilePhone !== true" floatLabel="never">
        <input matInput placeholder="{{ 'CREATE_ACCOUNT_PAGE.MOBILE_PHONE' | translate }}" [disabled]="isBusy || lockMobilePhone" name="mobilePhone" [(ngModel)]="user.mobilePhone">
        <span matSuffix matTooltip="{{ 'CREATE_ACCOUNT_PAGE.MOBILE_PHONE_INFO' | translate }}"><mat-icon>info</mat-icon></span>
      </mat-form-field>

      <mat-form-field *ngIf="clientApplication && clientApplication.settings.account.additionalCountryStepRequired === true && !orgIdParameter" floatLabel="never">
        <mat-label>{{ 'COUNTRY' | translate }}</mat-label>
        <mat-select [(ngModel)]="user.organization" name="country" [disabled]="isBusy" required>
          <mat-option *ngFor="let org of organizations" [value]="org">{{ org.name | translate }}</mat-option>
        </mat-select>
      </mat-form-field>

      <div style="margin: 15px 0;" *ngIf="!hasApproved && clientApplication && clientApplication.settings.account.requireTermsApproval === true" >
        <mat-checkbox [(ngModel)]="user.approvesTermsOfService" [disabled]="isBusy" name="approvesTermsOfService" ></mat-checkbox>
        <a href="{{clientApplication.termsOfServices.endUser.urlPdf}}" style="padding-left: 4px; font-size: 12px" target="_blank">{{ 'CREATE_ACCOUNT_PAGE.I_APPROVE' | translate }}</a>
      </div>

      <div style="margin: 15px 0;" *ngIf="!inviteRequiredButNotFound && clientApplication && clientApplication.settings.account.requireTermsApproval !== true && clientApplication.settings.account.termsOfServiceType != 'NONE' " >
        <a href="{{clientApplication.termsOfServices.endUser.urlPdf}}" style="font-size: 12px;" target="_blank">{{ 'CREATE_ACCOUNT_PAGE.I_APPROVE_CE' | translate:{name: clientApplication.settings.account.termsOfServiceProviderText}  }}</a>
      </div>

    </div>
  </div>
  <div>
    <div class="form-buttons" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between">
      <button type="button" mat-button routerLink="/" [disabled]="isBusy">{{ 'TO_START_PAGE' | translate }}</button>
      <button fxFlexOrder.xs="2" type="submit" mat-raised-button color="primary" [disabled]="isBusy || !accountForm.form.valid" (click)="createAccount()">{{ 'CREATE_ACCOUNT' | translate }}</button>
    </div>
  </div>

</div>
</form>

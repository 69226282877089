import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable()
export class UrlService {

  api = null;

  constructor(private route: ActivatedRoute) { }

  apiUrl(): String {

    if (this.api === null) {

      console.log("In UrlService.apiUrl()");
      console.log(location);

      var host = location.hostname;
      var origin = location.origin;

      console.log("Setting urls, host is " + host);
      console.log("URLs before:", this.api);

      this.api = environment.apiUrl
        .replace("{host}", host)
        .replace("{origin}", origin)

      console.log("URLs after:", this.api);

    }

    return this.api;

  }

	storeUrlState(): void {

		var targetUri = this.route.snapshot.queryParams.targetUri;
		if (targetUri !== undefined) {
			console.log("Storing targetUri in sessionStorage:", targetUri);
			sessionStorage.setItem("targetUri", targetUri);
		}

		var skipSSO = this.route.snapshot.queryParams.skipSSO;
		if (skipSSO !== undefined) {
			console.log("Storing skipSSO in sessionStorage:", skipSSO);
			sessionStorage.setItem("skipSSO", targetUri);
		}

		var isApp = this.route.snapshot.queryParams.isApp;
		var app_version = this.route.snapshot.queryParams.app_version;

		var appVersion;
		if (app_version !== null && app_version !== undefined){
      appVersion = app_version.split("-").join(".");
    } else {
      appVersion = "0.0.0"
    }
    var appPlatform = this.route.snapshot.queryParams.app_platform;

		if (isApp === "true") {
			console.log("Storing isApp, appVersion and appPlatform in sessionStorage:", isApp);
			sessionStorage.setItem("isApp", "true");
      sessionStorage.setItem("appVersion", appVersion);
      sessionStorage.setItem("appPlatform", appPlatform);
		} else {

      this.route.queryParams.subscribe(params => {
        if (params["isApp"] === "true") {
          console.log("Storing isApp in sessionStorage (2):", params["isApp"]);
          sessionStorage.setItem("isApp", "true");
        }

        if (params["app_version"] !== null && params["app_version"] !== undefined) {
          console.log("Storing appVersion in sessionStorage (2):", params["app_version"]);
          appVersion = params["app_version"].split("-").join(".");
          sessionStorage.setItem("appVersion", appVersion);
        } else {
          appVersion = "0.0.0";
          sessionStorage.setItem("appVersion", appVersion);
        }

        if (params["app_platform"] !== null && params["app_platform"] !== undefined) {
          console.log("Storing appPlatform in sessionStorage (2):", params["app_platform"] );
          sessionStorage.setItem("appPlatform", params["app_platform"]);
        }

        if (targetUri === undefined) {
          targetUri = params["targetUri"];
          if (targetUri !== undefined && targetUri !== "") {
            console.log("Storing targetUri in sessionStorage:", params["targetUri"]);
            sessionStorage.setItem("targetUri", params["targetUri"]);
          }
        }

        if (params["skipSSO"] === "true") {
          console.log("Storing skipSSO in sessionStorage (2):", skipSSO);
          sessionStorage.setItem("skipSSO", "true");
        }

      });

    }

		var implicitReceiptReference = this.route.snapshot.queryParams.implicitReceiptReference;
		if (implicitReceiptReference !== undefined && implicitReceiptReference !== null) {
			console.log("Storing implicitReceiptReference in sessionStorage:", implicitReceiptReference);
			sessionStorage.setItem("implicitReceiptReference", implicitReceiptReference);
		}

		var identifier = this.route.snapshot.queryParams.id;
		if (identifier !== undefined && identifier !== null) {
			console.log("Storing identifier in sessionStorage:", identifier);
			sessionStorage.setItem("identifier", identifier);
		}

		var identifierType = this.route.snapshot.queryParams.idt;
		if (identifierType !== undefined && identifierType !== null) {
			console.log("Storing identifierType in sessionStorage:", identifierType);
			sessionStorage.setItem("identifierType", identifierType);
		}

		var approves = this.route.snapshot.queryParams.a;
		if (approves === "true") {
			console.log("Storing approves in sessionStorage:", approves);
			sessionStorage.setItem("approves", "true");
		} else if (approves === "false") {
			console.log("Storing approves in sessionStorage:", approves);
			sessionStorage.setItem("approves", "false");
		}

	}

	private forwardQueryParams = new Set(["auto_login_tenant_id"])

	private getTargetUriQueryFwdParams = (): any =>  {

		let targetUri = sessionStorage.getItem("targetUri");
		if (targetUri === undefined || targetUri === null) {
			console.info("No targetUri in sessionStorage");
			return null
		}

		const url = new URL(targetUri);
		const params: any = new URLSearchParams(url.search);
		let foundForwardParams = {};
		if (params) {
			let paramKeys = Array.from(params.keys());
			if (paramKeys) {
				console.info("Found forward query params", JSON.stringify(paramKeys));
				paramKeys.filter((a :any) => this.forwardQueryParams.has(a)).forEach((p :any) => {
					foundForwardParams[p] = params.get(p)
				})
			}
		}
		return foundForwardParams;
	}
	
	

	checkSsoRedirect(ca): Boolean {

		var redirected = false;
		var storedSkipSSO = sessionStorage.getItem("skipSSO");
		var skipSSO = (storedSkipSSO === "true");
		console.log ("SSO REDIRECT", ca.settings.url);

		if (ca.settings.url.loginUrl && !skipSSO) {

			var storedIsApp = sessionStorage.getItem("isApp");
			var targetUri = sessionStorage.getItem("targetUri");
			var isApp = (storedIsApp === "true");
			var redirectUrl = isApp ? ca.settings.url.apiRootUrl + "/auth/appredirect" : ca.redirectUrl;

			console.log("SSO: storedIsApp", storedIsApp);
			console.log("SSO: isApp", isApp);
			console.log("SSO: redirectUrl 1", redirectUrl);
			console.log("SSO: targetUri", targetUri);

			if ((targetUri === undefined || targetUri === null) && isApp === false) {
				redirectUrl = ca.redirectUrl;
			} else if (targetUri !== undefined && targetUri !== null) {
			  redirectUrl = targetUri;
			}

			console.log("SSO: redirectUrl", redirectUrl);

			let forwardParams = this.getTargetUriQueryFwdParams();
			console.log("SSO, forwardParams:", JSON.stringify(forwardParams));

			sessionStorage.removeItem("isApp");
			sessionStorage.removeItem("targetUri");
			
			let finalUrl = ca.settings.url.loginUrl + "?clientApplicationId=" + ca.applicationId + "&targetUri=" + encodeURIComponent(redirectUrl)
			let paramKey: string;
			for(paramKey in forwardParams) {
				console.log("SSO, forwardParam KEY :", paramKey);
				finalUrl += "&" + paramKey + "=" + forwardParams[paramKey];
			}
			
			console.log("SSO, final url: " + finalUrl);

			document.location.href = finalUrl
			redirected = true;
		}

		return redirected;

	}

}

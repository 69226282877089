import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Subject }    from 'rxjs/Subject';
import { of }         from 'rxjs/observable/of';
import { ActivatedRoute, Router } from '@angular/router';
import { UrlService } from './url.service';

let httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class LogInService {

  constructor(private http: HttpClient, private route: ActivatedRoute, private url: UrlService) { }

  setClientApplicationHeader(applicationId: string){
    httpOptions.headers = httpOptions.headers.set("X-Findity-ClientApplicationId", applicationId);
  }

  logIn(username: String, password: String, isApp: Boolean, idpId: String, isSkipSSO: Boolean): Observable<any> {
    return this.http.post(this.url.apiUrl() + "/auth/signin", {"username": username, "password": password, "isApp": isApp, "idpId": idpId, "isSkipSSO": isSkipSSO}, httpOptions);
  }

  requestPassword(email: String, applicationId: String): Observable<any> {
    return this.http.post(this.url.apiUrl() + "/auth/password", {"email": email, "applicationId": applicationId }, httpOptions);
  }

  getInvitation(invitationId: String, signature: String): Observable<any> {

    if (signature === undefined) {
      signature = ""
    }

    return this.http.get(this.url.apiUrl() + "/auth/invitation?invitationId=" + invitationId + '&signature=' + signature, httpOptions);
  }

  validateSecurityCode(username: String, password: String, securityCode: String, isApp: Boolean): Observable<any> {
    return this.http.post(this.url.apiUrl() + "/auth/signin", {"username": username, "password": password, "securityCode": securityCode, "isApp": isApp}, httpOptions);
  }

  getExternalLoginUrls(username: String): Observable<any> {
    return this.http.post(this.url.apiUrl() + "/auth/externalloginurls", {email: username}, httpOptions);
  }

  getCountryInfoFromIP(): Observable<any> {
    //return this.http.get('assets/fake_rs.json'); - local test for fake data response
    return this.http.get('https://extreme-ip-lookup.com/json/');
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { AccountComponent } from './account/account.component';
import { ActivateAccountComponent } from './activate-account/activate-account.component';
import { PasswordComponent } from './password/password.component';
import { DelegateComponent } from './delegate/delegate.component';
import {CreateAmexAccountComponent} from "./create-amex-account/create-amex-account.component";
import { AppVersionLowComponent } from './app-version-low/app-version-low.component';

const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'login', component: LoginComponent },
	{ path: 'create-account', component: AccountComponent },
	{ path: 'activate-account', component: ActivateAccountComponent },
  { path: 'amex-activate-card', component: CreateAmexAccountComponent },
  { path: 'request-password', component: PasswordComponent },
  { path: 'request-password/:username', component: PasswordComponent },
  { path: 'delegate/:clientId', component: DelegateComponent },
  { path: 'app-version-low', component: AppVersionLowComponent },
];

@NgModule({
	imports: [ RouterModule.forRoot(routes, { useHash: true }) ],
	exports: [ RouterModule ]
})
export class AppRoutingModule { }

import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {UrlService} from "./url.service";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import { UtilitiesService } from '../shared/utilities.service';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable()
export class ExternalExpenseProviderService {

  constructor(private http: HttpClient, private urlService: UrlService, private utilitiesService: UtilitiesService) {
  }

  public getMyProviders(token: string): Observable<any[]> {
    httpOptions.headers = httpOptions.headers.set('Authorization', this.utilitiesService.getEncryptedToken(token));
    return this.http.get(this.urlService.apiUrl() + '/expenses/external/providers', httpOptions)
      .pipe(
        map((providers: any[]) => {
          return providers;
        })
      );
  }


}
